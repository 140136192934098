import {
    BoxBlacklistConfigurationResponse,
    BusinessMap,
    Detection,
    DetectionActions,
    DetectionsApiClient,
    DetectionStatus,
    DetectionTypes,
    SearchDetectionsRequest,
    XchangeApiClient
} from './../../../services/cyber-api';
import { autoinject, bindable, containerless, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BusinessMapsApiClient } from '../../../services/cyber-api';
import { StateApi } from '../../../services/state-api';
import { Toast } from 'utilities/toast';
import { Utilities } from 'utilities/utilities';
import { IEnrichedVessel } from '../../../models/i-enriched-vessel';

@autoinject()
@containerless()
export class fleetwideCountermeasuresBlade {
    @bindable() private onHide: (detection: Detection) => void = () => { };
    @bindable() private fleetVessels: BusinessMap[];
    @bindable() private businessMap: BusinessMap;
    @bindable() private detection: Detection;
    @observable private block: boolean;
    private saving: boolean = false;
    private keypressCallback: EventListenerOrEventListenerObject;
    private readonly requiredXchangeFirmwareVersion: string = '5.1';
    private DetectionTypes: typeof DetectionTypes = DetectionTypes;
    private DetectionStatus: typeof DetectionStatus = DetectionStatus;
    private DetectionActions: typeof DetectionActions = DetectionActions;
    private loading: boolean = true;
    private companyBlacklist: BoxBlacklistConfigurationResponse;
    private targetDetections: Detection[];
    private selectedVessels: IEnrichedVessel[] = [];
    private selectableVessels: IEnrichedVessel[] = [];
    private enrichedVessels: IEnrichedVessel[] = [];

    constructor(
        private businessMapApi: BusinessMapsApiClient,
        private xchangeApi: XchangeApiClient,
        private state: StateApi,
        private router: Router,
        private detectionsApi: DetectionsApiClient,
    ) {
        this.keypressCallback = this.keypress.bind(this);
    }

    private async attached(): Promise<void> {
        await this.initialize();
    }

    public async cancel(): Promise<void> {
        this.onHide(null);
    }

    public async save(): Promise<void> {
        this.saving = true;
        // Execute all others in list
        try {
            for (const x of this.selectedVessels) {
                // Check if vessel is valid to block/unblock domains on
                if (!x.compatible && !x.selectable) return;
                // Check if detection is available
                if (x.detection?.id) {
                    if (x.detection.id !== this.detection.id) {
                        this.detectionsApi.execute(x.detection.id, this.state.company()).then((detection) => {
                            this.enrichedVessels.find(y => y.detection?.id === x.detection.id).detection = detection;
                        });
                    } else {
                        // Execute initial detection
                        this.detectionsApi.execute(this.detection.id, this.state.company()).then((detection) => {
                            this.detection = detection;
                        });
                    }
                } else {
                    // Block/unblock domain directly on xchange
                    if (this.block) {
                        // block
                        this.xchangeApi.blockDomain(x.businessMap.xchangeBoxSerialNo, this.detection.target, this.state.company());
                    } else {
                        // unblock
                        this.xchangeApi.unblockDomain(x.businessMap.xchangeBoxSerialNo, this.detection.target, this.state.company());
                    }
                }
            }

            Toast.success(`${this.block ? 'Block' : 'Unblock'} action(s) queued`);
            this.saving = false;
            this.hide(this.detection);
            await this.initialize();
            await this.blockChanged();

        } catch (error) {
            this.saving = false;
            Toast.error(`Oops, ${error}`);
        }
    }

    private xchangeCompatibleHardware(softwareVersion: string): boolean {
        if (!softwareVersion) return undefined;

        // #16695 [XC-CM] Do not display "Block on this vessel" button if XChange firmware is <5.1
        // note: baffled that this comparison works! Javascript is... special
        // '5.3.0' > '5.2' true
        // '5.3.0' < '5.2' false
        // '5.3.0' < '5.2.1.3' false
        // '5.3.0' > '5.2.1.3' true

        return softwareVersion >= this.requiredXchangeFirmwareVersion;
    }

    private selectAllVessels(checked: boolean): boolean {
        if (checked) {
            this.selectedVessels = this.enrichedVessels.filter(x => x.compatible && x.selectable);
        } else {
            this.selectedVessels = this.enrichedVessels.filter(x => this.businessMap?.id === x.businessMap?.id && x.selectable);
        }
        return true;
    }

    public copyToClipboard(value: string): void {
        try {
            Utilities.copyToClipboard(value);
        } catch (error) {
            Toast.warning('Unable to copy to clipboard');
            return;
        }

        Toast.info('Copied to clipboard');
    }

    private async blockChanged(): Promise<void> {
        this.selectedVessels = [];
        await this.enrichVessels();
    }

    private async enrichVessels(): Promise<void> {
        this.loading = true;
        this.enrichedVessels = [];
        // Enrich all vessels
        for await (const vessel of this.fleetVessels) {
            const boxBlackList = this.companyBlacklist.dnsBlacklistConfigurations.find(x => x.boxSerialNumber === vessel.xchangeBoxSerialNo)
            const domainBlocked = boxBlackList?.domains?.find(x => x === this.detection.target);
            const compatible = this.xchangeCompatibleHardware(vessel.xChangeFirmwareVersion);
            const detection = this.businessMap.id === vessel.id ? this.detection : compatible ? this.targetDetections.find(x => x.attributes?.boxserial === vessel.xchangeBoxSerialNo) : null;
            let sameAction = false;

            if (detection?.id) {
                if (this.block) {
                    // Check if not blocked
                    sameAction = (detection.lastAction === DetectionActions.Unblock && detection.status === DetectionStatus.Succeeded)
                        || detection.lastAction === DetectionActions.Unspecified
                        || (detection.lastAction === DetectionActions.Block && detection.status === DetectionStatus.Failed)
                } else {
                    // Check if blocked
                    sameAction = (detection.lastAction === DetectionActions.Block && detection.status === DetectionStatus.Succeeded)
                        || (detection.lastAction === DetectionActions.Unblock && detection.status === DetectionStatus.Failed)
                }
            } else if (this.block) {
                // Check if not blocked
                sameAction = domainBlocked === undefined
            } else {
                // Check if blocked
                sameAction = domainBlocked !== undefined
            }
            // A vessel is selectable when a compatible hardware version, the action on the original detection is the same as the vessel and domains < 1000 (only for block)
            const selectable = compatible && sameAction && (!boxBlackList?.domains || (boxBlackList?.domains?.length < 1000 || domainBlocked !== undefined));

            // Push the enrichedVessels data to the list
            let enrichedVessel: IEnrichedVessel = {
                businessMap: vessel,
                domainBlocked,
                detection,
                compatible,
                selectable,
                message: boxBlackList?.domains?.length >= 1000 && (domainBlocked === undefined) && sameAction ? 'You have reached the limit of 1000 DNS blacklist entries on this XChange. Please delete entries directly in Portal 360 before proceeding.' : undefined
            };
            this.enrichedVessels.push(enrichedVessel);
            if (this.businessMap?.id === vessel?.id && sameAction)
                this.selectedVessels.push(enrichedVessel);
        }


        // Sort based on compatibility and vesselname
        let selected = this.enrichedVessels.filter(x => this.businessMap?.id === x.businessMap?.id);
        let selectable = this.enrichedVessels.filter(x => x.selectable && this.businessMap?.id !== x.businessMap?.id);
        let notSelectable = this.enrichedVessels.filter(x => !x.selectable && x.compatible && this.businessMap?.id !== x.businessMap?.id);
        let notCompatible = this.enrichedVessels.filter(x => !x.compatible);

        selected = selected.sort((a, b) => {
            return b.businessMap.name > a.businessMap.name ? -1 : 1
        });
        selectable = selectable.sort((a, b) => {
            return b.businessMap.name > a.businessMap.name ? -1 : 1
        });
        notSelectable = notSelectable.sort((a, b) => {
            return b.businessMap.name > a.businessMap.name ? -1 : 1
        });
        notCompatible = notCompatible.sort((a, b) => {
            return b.businessMap.name > a.businessMap.name ? -1 : 1
        });
        this.selectableVessels = selectable.concat(selected);

        // Concat the sorted lists
        this.enrichedVessels = selected.concat(selectable, notSelectable, notCompatible);
        this.loading = false;
    }

    private hide(detection: Detection): void {
        if (this.onHide)
            this.onHide(detection);
    }

    private async initialize(): Promise<void> {
        window.addEventListener('keydown', this.keypressCallback, false);
        // Get all blacklist item for the company
        this.companyBlacklist = await this.xchangeApi.blacklistForCompany(this.state.company());

        // Get all detections for the target
        const searchRequest = new SearchDetectionsRequest({
            companyId: this.state.company(),
            boxSerial: undefined,
            target: this.detection.target,
            type: this.detection.type
        })
        this.targetDetections = await this.detectionsApi.search(
            this.state.company(),
            searchRequest
        );

        this.block = (this.detection.lastAction === DetectionActions.Unspecified || this.detection.lastAction === DetectionActions.Unblock && (this.detection.status === DetectionStatus.Succeeded || this.detection.status === DetectionStatus.Open)) || (this.detection.lastAction === DetectionActions.Block && this.detection.status === DetectionStatus.Failed);
    }

    //#region Keypress Events for [Esc]
    private async detached(): Promise<void> {
        window.removeEventListener('keydown', this.keypressCallback);
    }

    private async keypress(e: KeyboardEvent): Promise<void> {
        if (e.code === 'Escape') this.onHide(null);
    }

    //#endregion
}
